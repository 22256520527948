import * as React from "react";
import Sheet from "@mui/joy/Sheet";
import CssBaseline from "@mui/joy/CssBaseline";
import Typography from "@mui/joy/Typography";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/joy/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Errors, FormData } from "../../type/user";
import db from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export default function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState<FormData>({
    phoneNumber: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<Errors>({
    phoneNumber: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const validateForm = (): boolean => {
    let isValid = true;
    const newErrors: Errors = { phoneNumber: "", password: "" };

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid 10-digit phone number";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name as keyof Errors]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleLoginClick = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const q = query(
        collection(db, "users"),
        where("phoneNumber", "==", formData.phoneNumber)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();

        if (formData.password === userData.password) {
          let dueDate = "";
          if (userData?.dueDate) {
            let value = new Date((userData?.dueDate - 25569) * 86400 * 1000);
            if (!isNaN(value.getTime())) {
              dueDate = value.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "2-digit",
              });
            }
          }

          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              username: userData.username,
              phoneNumber: userData.phoneNumber.toString(),
              role: userData.role,
              buyers: userData?.buyers,
              dueDate: dueDate,
              outstanding: userData?.outstanding,
              message: userData?.message,
            })
          );
          navigate("/");
          // Store user session or token here
        } else {
          setErrors({
            phoneNumber: "Invalid credentials",
            password: "Invalid credentials",
          });
        }
      } else {
        setErrors({
          phoneNumber: "Invalid credentials",
          password: "Invalid credentials",
        });
      }
    } catch (error) {
      console.log("Login error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <CssBaseline />
      <Sheet
        sx={{
          width: 300,
          mx: "auto",
          my: 15,
          py: 3,
          px: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRadius: "sm",
          boxShadow: "md",
          bgcolor: "#f9f7f3",
        }}
        variant="outlined"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography level="h4" component="h1">
            <b>Welcome!</b>
          </Typography>
          <Typography level="body-sm">Sign in to continue.</Typography>
        </div>

        <FormControl error={!!errors.phoneNumber}>
          <FormLabel>User ID</FormLabel>
          <Input
            name="phoneNumber"
            type="tel"
            placeholder="Enter your User ID"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            error={!!errors.phoneNumber}
          />
        </FormControl>

        <FormControl error={!!errors.password}>
          <FormLabel>Password</FormLabel>
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            value={formData.password}
            onChange={handleInputChange}
            error={!!errors.password}
            endDecorator={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            }
          />
        </FormControl>

        <Button
          onClick={handleLoginClick}
          loading={isLoading}
          disabled={isLoading}
          sx={{ mt: 1 }}
        >
          {isLoading ? "Logging in..." : "Log in"}
        </Button>

        <Typography
          endDecorator={<Link href="/sign-up">Sign up</Link>}
          sx={{ fontSize: "sm", alignSelf: "center" }}
        >
          Don&apos;t have an account?
        </Typography>
      </Sheet>
    </main>
  );
}
