import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react";
import { buyersColumn } from "../../utils";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { setBuyerRefresh } from "../../store/userSlice";

const BuyerTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { buyerRefresh } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserData()
  }, []);

  useEffect(() => {
    if (buyerRefresh) {
      fetchUserData()
      dispatch(setBuyerRefresh(false))
    }
  }, [buyerRefresh]);


  const fetchUserData = async () => {
    setIsLoading(true)
    try {
      const usersCollection = collection(db, "users"); // Reference to the 'users' collection
      const querySnapshot = await getDocs(usersCollection); // Fetch all documents

      const usersData = [];
      querySnapshot.forEach((doc) => {
        const user = doc.data();
        if (user.role === 'USER') {
          let dueDate = ""
          const date = new Date((user?.dueDate - 25569) * 86400 * 1000);
          if (!isNaN(date.getTime())) {
            dueDate = date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "2-digit",
            });
          }
          usersData.push({ ...user, id: doc.id, dueDate }); // Collect document ID and data
        }
      });
      setTableData(usersData);
    } catch (error) {
      console.error("Error fetching users collection:", error);
      return [];
    } finally {
      setIsLoading(false)
    }
  };

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = tableData.map((row) => {
      if (row.id === newRow.id) {
        return { ...row, ...newRow };
      }
      return row;
    });
    setTableData(updatedRows);
    return newRow;
  };

  return (
    <div className="mt-4 h-[600px]">
      <DataGrid
        pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        rows={tableData}
        columns={buyersColumn}
        getRowId={(row) => row.id}
        processRowUpdate={handleProcessRowUpdate}
        loading={isLoading}
        sx={{
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
            backgroundColor: "#566270",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important",

            // Adjust as needed
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
          },
        }}
      />
    </div>
  )
}

export default React.memo(BuyerTable);