import { createSlice } from "@reduxjs/toolkit";
import { UsersData } from "../type/user";

interface OrderState {
  user: UsersData[];
  refresh: boolean;
  buyerRefresh: boolean
}

const initialState: OrderState = {
  user: [],
  refresh: false,
  buyerRefresh: false
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.user = action.payload;
    },
    setRefresh: (state, action) => {
        state.refresh = action.payload;
    },
    setBuyerRefresh: (state, action) => {
        state.buyerRefresh = action.payload;
    },
  },
});

// Export the action and reducer
export const { setUsers, setRefresh, setBuyerRefresh } = UserSlice.actions;
export default UserSlice.reducer;
