import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react";
import { usersColumn } from "../../utils";
import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase";
import { setRefresh } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";

const UserTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { refresh } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserData()
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchUserData()
      dispatch(setRefresh(false))
    }
  }, [refresh]);

  const fetchUserData = async () => {
    setIsLoading(true)
    try {
      const usersCollection = collection(db, "users"); // Reference to the 'users' collection
      const querySnapshot = await getDocs(usersCollection); // Fetch all documents

      const usersData = [];
      querySnapshot.forEach((doc) => {
        const user = doc.data();
        usersData.push({ ...user, id: doc.id }); // Collect document ID and data
      });
      setTableData(usersData);
    } catch (error) {
      console.error("Error fetching users collection:", error);
      return [];
    } finally {
      setIsLoading(false)
    }
  };

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = tableData.map((row) => {
      if (row.id === newRow.id) {
        return { ...row, ...newRow };
      }
      return row;
    });
    setTableData(updatedRows);
    return newRow;
  };

  return (
    <div className="mt-4 h-[600px]">
      <DataGrid
        pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        rows={tableData}
        columns={usersColumn}
        getRowId={(row) => row.id}
        processRowUpdate={handleProcessRowUpdate}
        loading={isLoading}
        sx={{
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
            backgroundColor: "#566270",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important",

            // Adjust as needed
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
          },
        }}
      />
    </div>
  )
}

export default React.memo(UserTable);